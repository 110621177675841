<template>
    <div class="preview d-flex flex-column h-100">
        <div class="preview__recap-container" v-if="showRecapCard">
            <div class="preview__recap">
                <div>
                    <span class="preview__recap-unit" v-thousand-digits="impressionsCount"></span>
                    <span> {{ $t('impressions') }} </span>
                </div>
                <div>
                    <span class="preview__recap-date">{{ accessData.formatedStartDate }}</span>
                    <span class="d-flex align-items-center">
                        {{ $t('startDateAccess') }}
                        <ad-icon size="meduim" icon="info" variant="white" class="cursor-pointer ml-1" ref="targetRef" />

                        <base-tooltip :target="targetRef">
                            <div class="fsb-typo-para-regular">
                                {{ $t('recapCampaignAccessTooltip') }}
                            </div>
                        </base-tooltip>
                    </span>
                </div>
                <div>
                    <span class="preview__recap-budget" v-cents-to-euro="budget"></span>
                    <span>{{ $t('budgetTotalHT') }}</span>
                </div>
                <div>
                    <ui-button :label="$t('purchaseAccess')" variant="white" @click="openPurchaseModal" />
                </div>

                <base-modal
                    :modal-show="showPurchaseModal"
                    :title="$t('confirmPurchase')"
                    hide-header-close
                    :tertiary-label="$t('cancel')"
                    :show-secondary-button="false"
                    :primary-label="$t('confirm')"
                    :on-tertiary-button-click="closeModal"
                    :on-primary-button-click="confirmPurchase"
                    :is-primary-button-disabled="!acceptCgu || isPurchaseLoading"
                    :load-primary-button-spinner="isPurchaseLoading"
                >
                    <div class="fsb-typo-para-regular" style="color: var(--grayscale-gray-dark)">
                        {{ $t('estimatePrompt') }}
                    </div>

                    <div class="d-flex flex-row justify-content-between fsb-typo-para-medium">
                        <span>{{ $t('purchaseAmountHT') }}</span>
                        <span v-cents-to-euro="budget"></span>
                    </div>
                    <hr />
                    <div class="d-flex flex-row justify-content-between fsb-typo-para-medium">
                        <span>{{ $t('amountTTC') }}</span>
                        <span class="fsb-typo-heading-5" v-cents-to-euro="budget * 1.2"></span>
                    </div>

                    <base-checkbox name="acceptCgu" @update:checked="(value: boolean) => (acceptCgu = value)">
                        <span class="fsb-typo-btn-secondary mr-1">{{ $t('acceptCguText') }}</span>
                        <span class="fsb-typo-link-regular cursor-pointer" @click="downloadCGUPdf">{{ $t('acceptCguLink') }}</span>
                    </base-checkbox>
                </base-modal>
            </div>
        </div>

        <div class="preview__mobile-mockup" :style="{ paddingTop: showRecapCard ? '0px' : '88px' }">
            <img src="../../assets/access-mobileMockup.png" alt="mobile-mockup" />
        </div>
    </div>
</template>

<script setup lang="ts">
    import { useAccessDataStore, usePurchaseCampaignsStore } from '@/store/storeIndex';
    import { storeToRefs } from 'pinia';
    import { computed, ref } from 'vue';
    import { useRoute, useRouter } from 'vue-router';

    const accessData = useAccessDataStore();
    const purchaseCampaigns = usePurchaseCampaignsStore();

    const { budget, selectedOperations, zipCodes } = storeToRefs(accessData);
    const { impressionsCount } = storeToRefs(accessData);

    const isPurchaseLoading = ref(false);

    const showRecapCard = computed(() => {
        return selectedOperations.value.length && zipCodes.value.length;
    });

    const targetRef = ref(null);

    const showPurchaseModal = ref(false);
    const acceptCgu = ref(false);
    const openPurchaseModal = () => {
        showPurchaseModal.value = true;
    };
    const closeModal = () => {
        showPurchaseModal.value = false;
        acceptCgu.value = false;
    };

    const downloadCGUPdf = () => {
        const pdfUrl = process.env.VUE_APP_CGV;
        if (pdfUrl) {
            window.open(pdfUrl, '_blank');
        } else {
            console.error('CGU PDF URL not found in environment variables');
        }
    };

    const router = useRouter();
    const route = useRoute();
    const confirmPurchase = async () => {
        try {
            isPurchaseLoading.value = true;
            await purchaseCampaigns.purchaseCampaigns(route.params.token as string);
            isPurchaseLoading.value = false;

            router.replace({ name: 'payment' });
        } catch {
            router.push({ name: 'error' });
        }
    };
</script>

<style scoped lang="scss">
    :global(.tooltip .tooltip-inner) {
        text-align: left;
    }

    .preview {
        gap: 16px;
        padding: 16px;

        &__recap {
            display: flex;
            justify-content: space-between;
            align-items: center;
            flex-wrap: wrap;
            gap: 5px;
            background: #619ef9;
            color: #fefefe;
            font-weight: 500;
            font-size: 14px;
            width: 100%;
            max-width: 604px;
            border-radius: 16px;
            padding: 16px;

            &-container {
                display: flex;
                justify-content: center;
            }

            span {
                font-size: 12px;
                font-weight: 400;

                &:nth-child(1) {
                    font-weight: 700;
                    font-size: 14px;
                }
            }

            > div {
                display: grid;
                gap: 4px;
            }

            .fsb-ui-button {
                font-weight: 500;
            }
        }

        &__mobile-mockup {
            display: flex;
            justify-content: center;
            align-items: center;
            flex: 1 0;

            img {
                display: block;
                max-width: 100%;
                max-height: 100%;
                object-fit: contain;
                filter: drop-shadow(0px 10px 20px rgba(5, 5, 5, 0.25)) drop-shadow(0px -20px 20px rgba(254, 254, 254, 0.2));
            }
        }
    }

    .cursor-pointer {
        cursor: pointer;
    }
</style>
