import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, resolveDirective as _resolveDirective, createElementVNode as _createElementVNode, withDirectives as _withDirectives, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createTextVNode as _createTextVNode, renderList as _renderList, Fragment as _Fragment, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "budget-card" }
const _hoisted_2 = { class: "unit" }
const _hoisted_3 = { key: 0 }
const _hoisted_4 = { key: 1 }
const _hoisted_5 = { class: "duration" }
const _hoisted_6 = { class: "medias" }

import { useAccessDataStore } from '@/store/accessData';
    import { storeToRefs } from 'pinia';

    
export default /*@__PURE__*/_defineComponent({
  __name: 'BudgetsRecap',
  setup(__props) {

    const accessData = useAccessDataStore();
    const { impressionsCount } = storeToRefs(accessData);
    let { budget } = storeToRefs(accessData);
    const { getMediasToDisplay } = storeToRefs(accessData);

return (_ctx: any,_cache: any) => {
  const _component_b_card_text = _resolveComponent("b-card-text")!
  const _component_ad_icon = _resolveComponent("ad-icon")!
  const _component_b_badge = _resolveComponent("b-badge")!
  const _component_b_card = _resolveComponent("b-card")!
  const _directive_thousand_digits = _resolveDirective("thousand-digits")!
  const _directive_cents_to_euro = _resolveDirective("cents-to-euro")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_b_card, null, {
      default: _withCtx(() => [
        _createVNode(_component_b_card_text, { class: "budget-recap mb-4" }, {
          default: _withCtx(() => [
            _createElementVNode("div", null, [
              _withDirectives(_createElementVNode("span", null, null, 512), [
                [_directive_thousand_digits, _unref(impressionsCount)]
              ]),
              _createElementVNode("span", _hoisted_2, _toDisplayString(_ctx.$t('impressions')), 1)
            ]),
            (_unref(budget))
              ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
                  _withDirectives(_createElementVNode("span", null, null, 512), [
                    [_directive_cents_to_euro, _unref(budget)]
                  ])
                ]))
              : (_openBlock(), _createElementBlock("div", _hoisted_4, _cache[0] || (_cache[0] = [
                  _createElementVNode("span", null, "--", -1),
                  _createElementVNode("span", { class: "budget-recap__euro" }, "€", -1)
                ])))
          ], undefined, true),
          _: 1
        }),
        _createVNode(_component_b_card_text, { class: "medias-recap" }, {
          default: _withCtx(() => [
            _createElementVNode("div", _hoisted_5, [
              _createTextVNode(_toDisplayString(_ctx.$t('duration')) + " : ", 1),
              _createElementVNode("div", null, [
                _createVNode(_component_ad_icon, {
                  size: "medium",
                  icon: "calendar",
                  class: "calendar-icon"
                }),
                _createElementVNode("span", null, "3-10 " + _toDisplayString(_ctx.$t('days')), 1)
              ])
            ]),
            _createElementVNode("div", _hoisted_6, [
              _createTextVNode(_toDisplayString(_ctx.$t('mediasAccess')) + " : ", 1),
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(getMediasToDisplay), (media) => {
                return (_openBlock(), _createBlock(_component_b_badge, { key: media }, {
                  default: _withCtx(() => [
                    _createVNode(_component_ad_icon, { icon: media }, null, 8, ["icon"])
                  ], undefined, true),
                  _: 2
                }, 1024))
              }), 128))
            ])
          ], undefined, true),
          _: 1
        })
      ], undefined, true),
      _: 1
    })
  ]))
}
}

})