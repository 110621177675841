import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, resolveDirective as _resolveDirective, createElementVNode as _createElementVNode, withDirectives as _withDirectives, toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeStyle as _normalizeStyle } from "vue"
import _imports_0 from '../../assets/access-mobileMockup.png'


const _hoisted_1 = { class: "preview d-flex flex-column h-100" }
const _hoisted_2 = {
  key: 0,
  class: "preview__recap-container"
}
const _hoisted_3 = { class: "preview__recap" }
const _hoisted_4 = { class: "preview__recap-unit" }
const _hoisted_5 = { class: "preview__recap-date" }
const _hoisted_6 = { class: "d-flex align-items-center" }
const _hoisted_7 = { class: "fsb-typo-para-regular" }
const _hoisted_8 = { class: "preview__recap-budget" }
const _hoisted_9 = {
  class: "fsb-typo-para-regular",
  style: {"color":"var(--grayscale-gray-dark)"}
}
const _hoisted_10 = { class: "d-flex flex-row justify-content-between fsb-typo-para-medium" }
const _hoisted_11 = { class: "d-flex flex-row justify-content-between fsb-typo-para-medium" }
const _hoisted_12 = { class: "fsb-typo-heading-5" }
const _hoisted_13 = { class: "fsb-typo-btn-secondary mr-1" }

import { useAccessDataStore, usePurchaseCampaignsStore } from '@/store/storeIndex';
    import { storeToRefs } from 'pinia';
    import { computed, ref } from 'vue';
    import { useRoute, useRouter } from 'vue-router';

    
export default /*@__PURE__*/_defineComponent({
  __name: 'RightContainer',
  setup(__props) {

    const accessData = useAccessDataStore();
    const purchaseCampaigns = usePurchaseCampaignsStore();

    const { budget, selectedOperations, zipCodes } = storeToRefs(accessData);
    const { impressionsCount } = storeToRefs(accessData);

    const isPurchaseLoading = ref(false);

    const showRecapCard = computed(() => {
        return selectedOperations.value.length && zipCodes.value.length;
    });

    const targetRef = ref(null);

    const showPurchaseModal = ref(false);
    const acceptCgu = ref(false);
    const openPurchaseModal = () => {
        showPurchaseModal.value = true;
    };
    const closeModal = () => {
        showPurchaseModal.value = false;
        acceptCgu.value = false;
    };

    const downloadCGUPdf = () => {
        const pdfUrl = process.env.VUE_APP_CGV;
        if (pdfUrl) {
            window.open(pdfUrl, '_blank');
        } else {
            console.error('CGU PDF URL not found in environment variables');
        }
    };

    const router = useRouter();
    const route = useRoute();
    const confirmPurchase = async () => {
        try {
            isPurchaseLoading.value = true;
            await purchaseCampaigns.purchaseCampaigns(route.params.token as string);
            isPurchaseLoading.value = false;

            router.replace({ name: 'payment' });
        } catch {
            router.push({ name: 'error' });
        }
    };

return (_ctx: any,_cache: any) => {
  const _component_ad_icon = _resolveComponent("ad-icon")!
  const _component_base_tooltip = _resolveComponent("base-tooltip")!
  const _component_ui_button = _resolveComponent("ui-button")!
  const _component_base_checkbox = _resolveComponent("base-checkbox")!
  const _component_base_modal = _resolveComponent("base-modal")!
  const _directive_thousand_digits = _resolveDirective("thousand-digits")!
  const _directive_cents_to_euro = _resolveDirective("cents-to-euro")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (showRecapCard.value)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _createElementVNode("div", _hoisted_3, [
            _createElementVNode("div", null, [
              _withDirectives(_createElementVNode("span", _hoisted_4, null, 512), [
                [_directive_thousand_digits, _unref(impressionsCount)]
              ]),
              _createElementVNode("span", null, _toDisplayString(_ctx.$t('impressions')), 1)
            ]),
            _createElementVNode("div", null, [
              _createElementVNode("span", _hoisted_5, _toDisplayString(_unref(accessData).formatedStartDate), 1),
              _createElementVNode("span", _hoisted_6, [
                _createTextVNode(_toDisplayString(_ctx.$t('startDateAccess')) + " ", 1),
                _createVNode(_component_ad_icon, {
                  size: "meduim",
                  icon: "info",
                  variant: "white",
                  class: "cursor-pointer ml-1",
                  ref_key: "targetRef",
                  ref: targetRef
                }, null, 512),
                _createVNode(_component_base_tooltip, { target: targetRef.value }, {
                  default: _withCtx(() => [
                    _createElementVNode("div", _hoisted_7, _toDisplayString(_ctx.$t('recapCampaignAccessTooltip')), 1)
                  ], undefined, true),
                  _: 1
                }, 8, ["target"])
              ])
            ]),
            _createElementVNode("div", null, [
              _withDirectives(_createElementVNode("span", _hoisted_8, null, 512), [
                [_directive_cents_to_euro, _unref(budget)]
              ]),
              _createElementVNode("span", null, _toDisplayString(_ctx.$t('budgetTotalHT')), 1)
            ]),
            _createElementVNode("div", null, [
              _createVNode(_component_ui_button, {
                label: _ctx.$t('purchaseAccess'),
                variant: "white",
                onClick: openPurchaseModal
              }, null, 8, ["label"])
            ]),
            _createVNode(_component_base_modal, {
              "modal-show": showPurchaseModal.value,
              title: _ctx.$t('confirmPurchase'),
              "hide-header-close": "",
              "tertiary-label": _ctx.$t('cancel'),
              "show-secondary-button": false,
              "primary-label": _ctx.$t('confirm'),
              "on-tertiary-button-click": closeModal,
              "on-primary-button-click": confirmPurchase,
              "is-primary-button-disabled": !acceptCgu.value || isPurchaseLoading.value,
              "load-primary-button-spinner": isPurchaseLoading.value
            }, {
              default: _withCtx(() => [
                _createElementVNode("div", _hoisted_9, _toDisplayString(_ctx.$t('estimatePrompt')), 1),
                _createElementVNode("div", _hoisted_10, [
                  _createElementVNode("span", null, _toDisplayString(_ctx.$t('purchaseAmountHT')), 1),
                  _withDirectives(_createElementVNode("span", null, null, 512), [
                    [_directive_cents_to_euro, _unref(budget)]
                  ])
                ]),
                _cache[1] || (_cache[1] = _createElementVNode("hr", null, null, -1)),
                _createElementVNode("div", _hoisted_11, [
                  _createElementVNode("span", null, _toDisplayString(_ctx.$t('amountTTC')), 1),
                  _withDirectives(_createElementVNode("span", _hoisted_12, null, 512), [
                    [_directive_cents_to_euro, _unref(budget) * 1.2]
                  ])
                ]),
                _createVNode(_component_base_checkbox, {
                  name: "acceptCgu",
                  "onUpdate:checked": _cache[0] || (_cache[0] = (value) => (acceptCgu.value = value))
                }, {
                  default: _withCtx(() => [
                    _createElementVNode("span", _hoisted_13, _toDisplayString(_ctx.$t('acceptCguText')), 1),
                    _createElementVNode("span", {
                      class: "fsb-typo-link-regular cursor-pointer",
                      onClick: downloadCGUPdf
                    }, _toDisplayString(_ctx.$t('acceptCguLink')), 1)
                  ], undefined, true),
                  _: 1
                })
              ], undefined, true),
              _: 1
            }, 8, ["modal-show", "title", "tertiary-label", "primary-label", "is-primary-button-disabled", "load-primary-button-spinner"])
          ])
        ]))
      : _createCommentVNode("", true),
    _createElementVNode("div", {
      class: "preview__mobile-mockup",
      style: _normalizeStyle({ paddingTop: showRecapCard.value ? '0px' : '88px' })
    }, _cache[2] || (_cache[2] = [
      _createElementVNode("img", {
        src: _imports_0,
        alt: "mobile-mockup"
      }, null, -1)
    ]), 4)
  ]))
}
}

})