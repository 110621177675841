<template>
    <b-navbar class="access-navbar" toggleable="lg">
        <b-navbar-nav class="m-auto">
            <img src="../../assets/logo_spoticar.png" alt="logo_spoticar" />
        </b-navbar-nav>
    </b-navbar>
</template>

<script setup lang="ts"></script>

<style lang="scss" scoped>
    .access-navbar {
        min-height: 64px;
        background: #ffffff;
        border: 1px solid #dbdbdb;
    }
</style>
