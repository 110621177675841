import { defineStore } from 'pinia';
import { accessStoreState } from '@/interfaces/stores/accessStoreState';
import { addDays, formatedDateToFront } from '@/helpers/date';

export const useAccessDataStore = defineStore('useAccessDataStore', {
    state: () =>
        ({
            selectedOperations: [],
            zipCodes: [],
            budget: 0,
            minAmount: 20000,
            maxAmount: 30000,
        } as accessStoreState),
    getters: {
        minBudget(state): number {
            return state.minAmount * state.selectedOperations.length;
        },
        maxBudget(state): number {
            return state.maxAmount * state.selectedOperations.length;
        },
        budgetInEuros(state): number {
            return state.budget / 100;
        },
        impressionsCount(): number {
            return (this.budgetInEuros * 1000) / 2.5;
        },
        getCampaignStartDate(): Date {
            const startDate = addDays(3);
            return startDate;
        },
        getCampaignEndDate(): Date {
            const endDate = addDays(13);
            return endDate;
        },
        getMediasToDisplay(state): string[] {
            const mediasIcon: string[] = ['DSK'];
            if (state.budget >= 39900) {
                mediasIcon.push('MOB');
            }
            if (state.budget >= 59900) {
                mediasIcon.push('FBK');
            }
            return mediasIcon;
        },
        formatedStartDate(): string {
            return formatedDateToFront(this.getCampaignStartDate);
        },
    },
    actions: {},
});
