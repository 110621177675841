import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, unref as _unref, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, isRef as _isRef, withCtx as _withCtx, createVNode as _createVNode } from "vue"

const _hoisted_1 = { class: "first-bloc mb-4" }
const _hoisted_2 = { class: "fsb-typo-heading-5" }
const _hoisted_3 = { class: "sub-title fsb-typo-para-regular" }
const _hoisted_4 = { class: "option__desc" }
const _hoisted_5 = { class: "option__title" }
const _hoisted_6 = { class: "option__small" }
const _hoisted_7 = {
  key: 0,
  class: "multiselect__single"
}

import { useAccessOperationsStore } from '@/store/accessOperations';
    import { useAccessDataStore } from '@/store/storeIndex';
    import { storeToRefs } from 'pinia';
    import { onMounted, ref, watch } from 'vue';
    import { useRoute, useRouter } from 'vue-router';

    
export default /*@__PURE__*/_defineComponent({
  __name: 'AdsList',
  setup(__props) {

    const route = useRoute();
    const router = useRouter();
    const accessData = useAccessDataStore();

    const { selectedOperations } = storeToRefs(accessData);

    const accessOperationsStore = useAccessOperationsStore();

    const { accessOperations } = storeToRefs(accessOperationsStore);
    const operationsOptions = ref([] as { name: string; date: string }[]);

    watch(
        () => accessOperations.value,
        (operations) => {
            operationsOptions.value = operations.details.products_list.map((product) => {
                return {
                    name: product.name,
                    date: product.car_add_date,
                };
            });
        }
    );

    onMounted(async () => {
        const token = route.params.token as string;
        try {
            await accessOperationsStore.getAccessOperations(token);
        } catch {
            router.push({ name: 'error' });
        }
    });

return (_ctx: any,_cache: any) => {
  const _component_vue_multi_select = _resolveComponent("vue-multi-select")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("h5", _hoisted_2, "1- " + _toDisplayString(_ctx.$t('selectAccessOperations')), 1),
    _createElementVNode("label", _hoisted_3, [
      _createTextVNode(_toDisplayString(_ctx.$t('publishedAds')) + " ", 1),
      _cache[1] || (_cache[1] = _createElementVNode("span", null, "●", -1))
    ]),
    _createVNode(_component_vue_multi_select, {
      modelValue: _unref(selectedOperations),
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => (_isRef(selectedOperations) ? (selectedOperations).value = $event : null)),
      options: operationsOptions.value,
      multiple: true,
      "close-on-select": false,
      "max-height": 192,
      "show-labels": false,
      searchable: false,
      label: "name",
      "track-by": "name",
      placeholder: _ctx.$t('adsToHighlight')
    }, {
      option: _withCtx((props) => [
        _createElementVNode("div", _hoisted_4, [
          _createElementVNode("span", _hoisted_5, _toDisplayString(props.option.name), 1),
          _createElementVNode("span", _hoisted_6, _toDisplayString(props.option.date), 1)
        ])
      ]),
      selection: _withCtx(({ values }) => [
        (values.length)
          ? (_openBlock(), _createElementBlock("span", _hoisted_7, _toDisplayString(values.length) + " " + _toDisplayString(_ctx.$t('selectedAds')), 1))
          : _createCommentVNode("", true)
      ]),
      _: 1
    }, 8, ["modelValue", "options", "placeholder"])
  ]))
}
}

})